import React from 'react';
import styles from './styles.module.scss';
import Typography2 from '@c/Typographies/Typography2';
import { theme } from '../../../../../theme/theme';

const NumberedTitle = ({
  number,
  title,
}: {
  number: number;
  title: string;
}) => {
  return (
    <div className={styles.row}>
      <div className={styles.number}>
        <Typography2
          weight="700"
          size="xxl"
          className={styles.number}
          color={theme.palette.secondary.main}
        >
          {number}
        </Typography2>
      </div>
      <Typography2 weight="600" size="xl">
        {title}
      </Typography2>
    </div>
  );
};

export default NumberedTitle;
