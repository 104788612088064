import HeaderRedesigned from '@c/HeaderComponents/HeaderRedesigned';
import CommonFooter from '@c/Pages/Common/Footer';
import Typography2 from '@c/Typographies/Typography2';
import { NextPage } from 'next';
import Link from 'next/link';
import Head from 'next/head';
import styles from './styles.module.scss';
import { theme } from '../../theme/theme';
import NumberedTitle from '@c/Pages/App/Components/NumberedTitle';
import Image from 'next/image';
import { Button, useMediaQuery } from '@mui/material';
import { isUserLoggedIn } from '../../utils/user.utils';
import { useDispatch } from 'react-redux';
import { toggleAuthModal } from '../../store/actions/user.actions';

export async function getServerSideProps(context) {
  const { identity, redirectUrl } = context.query;

  if (identity) {
    context.res.setHeader('Set-Cookie', [
      `identity=${identity}; HttpOnly; Secure; Path=/;`,
      `session=exists; Path=/;`,
    ]);
  }

  if (redirectUrl) {
    return {
      redirect: {
        destination: redirectUrl,
        permanent: true,
      },
    };
  }

  return {
    props: {},
  };
}

const AppLandingPage: NextPage = () => {
  const isLoggedIn = isUserLoggedIn();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <HeaderRedesigned />
      <Head>
        <title>Секспросвет | СП - Информация</title>
        <meta key="googlebot" name="googlebot" content="noindex,follow" />
        <meta key="robots" name="robots" content="noindex,follow" />
        {/* <meta name="description" content={blog.title} /> */}
      </Head>
      <main className={styles.container}>
        <div className={styles.content_container}>
          <div className={styles.top_container}>
            <div className={styles.left_container}>
              <div className={styles.title}>
                <Typography2 weight="700" size={isMobile ? 'xl' : 'xxxl'}>
                  Всегда знайте, кто вам звонит
                </Typography2>
              </div>
              <Typography2
                weight="400"
                size={isMobile ? 'md' : 'xl'}
                color={theme.palette.textTransluent.main}
              >
                Более 500 миллионов пользователей со всего мира
              </Typography2>
              <div className={styles.tagsContainer}>
                <div className={styles.tagContainer}>
                  <Typography2
                    weight="600"
                    size={isMobile ? 'm' : 'xl'}
                    color={theme.palette.secondary.main}
                    className={styles.tag_typography}
                  >
                    Знайте Кто Звонит
                  </Typography2>
                </div>
                <div className={styles.tagContainer}>
                  <Typography2
                    weight="600"
                    size="xl"
                    color={theme.palette.secondary.main}
                    className={styles.tag_typography}
                  >
                    Избегайте Проблем
                  </Typography2>
                </div>
                <div className={styles.tagContainer}>
                  <Typography2
                    weight="600"
                    size="xl"
                    color={theme.palette.secondary.main}
                    className={styles.tag_typography}
                  >
                    Помогайте Коллегам
                  </Typography2>
                </div>
              </div>
            </div>
            <div className={styles.right_container}>
              <Image
                style={{ objectFit: 'contain' }}
                fill
                src={'/scr_1.png'}
                alt={'App screenshot'}
                placeholder="blur"
                blurDataURL="/map.png"
              />
            </div>
          </div>
          <div className={styles.bottom_container}>
            <Typography2 weight="700" size={isMobile ? 'xl' : 'xxl'}>
              Как получить доступ к приложению?
            </Typography2>
            <div className={styles.guide_list}>
              <div className={styles.guide_bullet_point}>
                <NumberedTitle number={1} title="Зарегистрируйтесь" />
                <Typography2>
                  Используйте единый логин и пароль как для приложения по
                  пробиву номеров, так и для размещения на сайте
                </Typography2>
              </div>
              <div className={styles.guide_bullet_point}>
                <NumberedTitle number={2} title="Скачайте приложение" />
                <Typography2>
                  Скачайте и установите приложение с нашего официального сайта
                </Typography2>
              </div>
              <div className={styles.guide_bullet_point}>
                <NumberedTitle number={3} title="Пробивайте номера" />
                <Typography2>
                  Одним нажатием кнопки включайте определитель номеров. Также
                  анонимно делитесь оценками и отзывами.
                </Typography2>
              </div>
            </div>
            {!isLoggedIn ? (
              <Button
                variant="contained"
                size="large"
                onClick={() => dispatch(toggleAuthModal())}
              >
                <Typography2
                  color={theme.palette.secondary.main}
                  weight="700"
                  size="m"
                >
                  Войти или Зарегистрироваться
                </Typography2>
              </Button>
            ) : (
              <Link href={'/profile/app'}>
                <Button variant="contained" size="large">
                  <Typography2
                    color={theme.palette.secondary.main}
                    weight="700"
                    size="l"
                  >
                    Перейти на страницу скачиваиня
                  </Typography2>
                </Button>
              </Link>
            )}
          </div>
        </div>
      </main>
      <CommonFooter />
    </>
  );
};

export default AppLandingPage;
